import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./privacypolicy.css";
import Contact from "../../components/Contact/Contact";
import Footer1 from "../../components/Footer1/Footer1";


const Privacypolicy = () => {
  return (
    <div>
      <Navbar />
      <div className="calculator-card">
        <h1>Privacy Policy</h1>
      </div>
      <div className="policy-content">
        <h4 className="font-color">
          WEFINANCE Solutions Limited Privacy Policy
        </h4>
        <p>
          Privacy matters.
          <br />
          <br />
          At Wefinance Solutions Limited we know that Data Privacy is important
          today, and we want you to enjoy your interaction with us whilst
          knowing that we value your Personal Data and that we protect it.
          <br />
          <br />
          Here, you will find an overview of how we process your Personal Data,
          the purposes for which we process it, and how you benefit. You’ll also
          see what your rights are and how you can contact us.
          <br />
          <br />
          Our website is available for visiting and making enquiry and also for
          our visitors who are interested in receiving our newsletters and
          updates.
          <br />
          <br />
          This is an online Privacy Policy that explains in details what we
          require, how we receive, use, share and protect our customers personal
          details and our visitors information who visits our site for more
          enquires of our products and services.
          <br />
          <br />
          As a Wefinance Solutions Limited customer, be guaranteed that we keep
          our trust as we protect your vital and personal information.
          <br />
          <br />
          Wefinance Solutions customers have the leverage to make decisions of
          how their personal information may be used and shared as we encourage
          our clients to make choices that enables us to provide excellent
          products and services that aids to meet your financial needs.
        </p>
        <h4 className="font-color">SECURITY OF PRIVATE INFORMATION</h4>
        <p>
          Protecting your private information is our priority as such, we
          maintain physical and electronic protection that complies with the
          federal law directives, which obligates us to ensure your information
          is protected from all unlawful access, use, alterations and
          destruction and any other unpredicted illegal processing.
          <br />
          <br />
          We participate in continuous training of our staff in the adequate
          management of personal information, when we use other organizations to
          provide services, we require them to guard the confidentiality of our
          client’s personal information they received from us.
          <br />
          <br />
          At Wefinance Solutions Limited, we take information privacy extremely
          important, as we provide our customers with satisfactory security to
          protect such personal information from unlawful access. It is required
          that any third party who renders services on our behalf to comply with
          compliance values to guard our client’s information.
          <br />
          <br />
          We are delighted to declare that we comply with international best
          practices and requirements.
        </p>
        <h4 className="font-color"> THE INFORMATION WE COLLECT</h4>
        <p>
          Wefinance Solutions Limited will solemnly collect information
          necessary in relations to transactions with relevant rules and
          regulations.
        </p>
        <h4 className="font-color"> HOW DO WE COLLECT INFORMATION?</h4>
        <p>
          Wefinance Solutions Limited gathers information from different
          sources, and it may relate to any of our products or services we
          render which includes;
        </p>
        <ul class="list-disc">
          <li>Opening of Accounts </li>
          <li>Application for loans.</li>
          <li>
            When you call our customer service desk for complains and disputes.
          </li>
          <li>
            Provision of account information such as; name, gender, date of
            birth, address, mobile numbers and work address.
          </li>
          <li>
            Provision of personal identity such as; photo ID, passport
            information, National ID card and nationality
          </li>
          <li>Creation of account on the mobile banking apps</li>
          <li>
            We carry out due diligence checks and obtain information that we
            need to support our regulatory obligations, e.g. information about
            transaction details, detection of any suspicious and unusual
            activities.
          </li>
        </ul>
        <h4 className="font-color">HOW WE USE YOUR INFORMATION</h4>
        <ul class="list-disc">
          <li>
            Wefinance Solutions Limited uses the information provided and
            authorized by our customers.
          </li>
          <li>
            We use the information collected to provide adequate and swift
            services as we manage our business and render outstanding customer
            services.
          </li>
          <li>
            We use this information to detect and prevent financial crimes
            including fraud, money laundering, terrorism financing and
            proliferation financing and also to ensure security and business
            continuity.
          </li>
          <li>
            Information are used to meet compliance obligations, to comply with
            rules and regulations and to share with regulators when required.
          </li>
          <li>
            We may send you(customer) information via email or mobile number,
            regarding your account details or your transactions.
          </li>
          <li>
            We may reach you through phone calls, letters, emails, WhatsApp
            chats and it may be recorded for instructions references and to
            improve our products and services.
          </li>
        </ul>
        <h4 className="font-color">WHO WE SHARE YOUR INFORMATION WITH?</h4>
        <p>
          We may share your information only when we have consent and approval
          to do so,as well as when we have to meet regulators requirements
        </p>
        <h4 className="font-color">
          WHAT ARE YOUR RIGHTS OVER YOUR PERSONAL INFORMATION?
        </h4>
        <p>
          <ul class="list-disc">
            <li>
              <b>RIGHT OF INDIVIDUALITY –</b>
              <br />
              When asked to provide any personal or private information, you
              will be informed of the reason for the request and why it is
              necessary.
            </li>
            <br />

            <li>
              <b>RIGHT OF PERSONAL ACCESS – </b>Individuals have the right to
              access personal and financial information.
            </li>
            <br />
            <li>
              <b> RIGHT TO SUSPENSION – </b>Individuals have the right to
              suspend, withdraw and process their information at any given time
              as we are allowed to store the personal information for future
              access.
            </li>
            <br />
            <li>
              <b> RIGHT TO DELETION –</b> Individuals have the right to request
              for cancellation and deletion their personal information where
              there is no compelling legal or regulatory obligation for its
              continued processing.
            </li>
            <br />
            <li>
              <b>RIGHT TO DATA MOVABILITY – </b>We will ensure that personal
              data is moved, copied or transferred easily from one IT
              environment to another in a safe and secure way, without
              interference to usability.
            </li>
            <br />
            <li>
              <b>RIGHT OF OBJECTION – </b>Individuals have the right to object
              to our dispensation of their information in some conditions.
            </li>
          </ul>
          For further information on any of this Privacy Policy, contact us at
          163, Sinari Daranijo, Victoria Island Lagos.
          <br />
          <br />
          <b>Note: </b>This Privacy Policy may be updated periodically.
        </p>
      </div>
      <Contact />
      <Footer1 />
    </div>
  );
};

export default Privacypolicy;
