import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import reportWebVitals from './reportWebVitals';
import Navbar from "./components/Navbar/Navbar";
import AboutPage from './pages/AboutPage/AboutPage';
import ServicePage from './pages/ServicePage/ServicePage';
import Faq from './pages/FaqPage/Faq';
import Calculator from './pages/LoanCalculator/Calculator';
import Thanks from './pages/ThankyouPage/Thanks';
import Quickloan from './pages/Quickloan/Quickloan';
import Privacypolicy from './pages/PrivacyPolicy/Privacypolicy';


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
   {
    path: "/",
    element: <Navbar />,
  },
  {
    path: "/privacypolicy",
    element: <Privacypolicy />,
  },
  {
    path: "/about",
    element: <AboutPage/>,
  },

  {
    path: "/aboutus",
    element: <AboutPage />,
  },
  {
    path: "/services",
    element: <ServicePage />,
  },
  {
    path: "/faq",
    element: <Faq/>,
  },
  {
    path: "/quickloan",
    element: <Quickloan/>,
  },
  {
    path: "/loancalculator",
    element: <Calculator/>,
  },
  {
    path: "/thankyou",
    element: <Thanks/>,
  },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
